import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Header from "../components/header"
import BackgroundWrapper from "../components/wrapper-background"
import ContentWrapper from "../components/wrapper-content"
import theme from "../theme"

const Content = styled(ContentWrapper)`
  color: ${theme.color.white}; 
`

const Terms = () => (
  <Layout>
    <SEO title="Terms" />

    <Header />

    <BackgroundWrapper className="backgroundBlue">

      <Content>
 
        <h1>Terms</h1>
        
        <h2>UK</h2>

        <p>Orders from the UK will be shipped either first class via Royal Mail. Shipping is included in the price you pay for your beans, please allow up to 7 working days for delivery.  Chiva Coffee does not add on any handling fee to you.</p>

        <h2>Returns</h2>

        <p>All sales are final, however if there is a problem with your beans, or you weren’t happy for any reason, please get in touch with our customer service team at <a href="mailto:hola@chivacoffee.com">hola@chivacoffee.com</a>. Please also get in touch if you have any feedback at all, it will help us improve your cup of coffee :)</p>
     
      </Content>
      
    </BackgroundWrapper>

  </Layout>
)

export default Terms
